/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Search {
  color: $greyDarkest;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 37px;
  position: relative;
}

.Filters {
  width: 22%;
}

.SearchProductList {
  width: 75.5%;
}

.SearchName {
  font-size: $fontSize-xl;
  font-weight: $font-weight-m;
  line-height: 26px;
  margin-top: 5px;

  & > span {
    color: $greyLighter;
    font-size: 20px;
  }
}

.SearchProductListTop {
  border-bottom: 1px solid $greyMiddle;
  margin-bottom: 16px;
}

.NoSearchResultContainer {
  padding-top: 15px;

  > *:first-child {
    margin-bottom: -25px;
  }
}

.NoSearchResultContentContainer {
  margin-left: -$paddingLeft;
  margin-right: -$paddingRight;
}

.ProductSliderWrapper {
  padding-top: 15px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .Search {
    display: block;
    margin-top: 15px;
  }

  .SearchProductList {
    width: 100%;
  }

  .Filters {
    width: 100%;
  }
}

@media screen and ( $mobile-media ) {

  .NoSearchResultContainer {

    > *:first-child {
      margin-bottom: -20px;
    }
  }

  .NoSearchResultContentContainer {
    margin-left: -$mobilePaddingLeft;
    margin-right: -$mobilePaddingRight;
  }
}
